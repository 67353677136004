import styled from "styled-components";
import bgImage from "../../images/bg/hero-bg-2.jpg"
export const StyledBgImgSection = styled.section`
    background: url(${bgImage}) no-repeat center center / cover;
    &.fixed-bg{
   		background: url(${bgImage}) no-repeat bottom center / cover fixed;
    }
	:before{
		position: absolute;
	    content: '';
	    background: rgba(0, 67, 139, 0.85);
	    width: 100%;
	    height: 100%;
	    top: 0;
	    left: 0;
	}
`

export const StyledServiceItem = styled.div`
	z-index: 0;
    position: relative;
    background: #ffffff;
    box-shadow: 0 0 0 1px #ebebeb;
    box-sizing: border-box;
    transition: box-shadow 0.3s ease,transform 0.3s ease,z-index 0s 0.3s ease,-webkit-transform 0.3s ease;
    :hover {
	    z-index: 1;
	    box-shadow: 0 8px 50px rgba(0,0,0,0.2);
	    transform: scale(1.05);
	    border-radius: 4px;
	    transition: box-shadow 0.1s ease,transform 0.1s ease,z-index 0s 0.1s ease,-webkit-transform 0.3s ease;
	}
`
